<template>
    <div>
        Saliendo...
    </div>
</template>

<script>
import { logout } from '../../services/operators.service'

export default {
    async mounted() {
        localStorage.clear()
        await logout()
        await this.$router.push('login')
    }
}
</script>
